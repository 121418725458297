var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('article',{staticClass:"article-page"},[(_vm.pageData)?[_c('header',{staticClass:"article-detail__header",class:{
          'article-detail__header--lychee': _vm.pageData.data.fields.header_color === 'lychee',
          'article-detail__header--aubergine': _vm.pageData.data.fields.header_color === 'aubergine',
          'article-detail__header--rasberry': _vm.pageData.data.fields.header_color === 'rasberry'
      }},[_c('div',{staticClass:"article-detail__container"},[_c('div',{staticClass:"article-detail__header--content"},[_c('div',{staticClass:"article-detail__header--content--half"},[_c('div',{staticClass:"article-detail__header--content--inner"},[_c('div',{staticClass:"article-detail__header--info"},[_c('div',{staticClass:"articl-detail__header--tags"},[(_vm.pageData.data.fields.resource_type)?_c('span',{staticClass:"tag",class:{
                      'tag--aubergine': _vm.pageData.data.fields.header_color === 'lychee',
                      'tag--white': _vm.pageData.data.fields.header_color === 'aubergine' || _vm.pageData.data.fields.header_color === 'rasberry',
                    }},[_vm._v(_vm._s(_vm.pageData.data.fields.resource_type))]):_vm._e(),(_vm.pageData.data.fields.topic)?_c('span',{staticClass:"tag",class:{
                        'tag--aubergine': _vm.pageData.data.fields.header_color === 'lychee',
                        'tag--white': _vm.pageData.data.fields.header_color === 'aubergine' || _vm.pageData.data.fields.header_color === 'rasberry',
                      }},[_vm._v(_vm._s(_vm.pageData.data.fields.topic))]):_vm._e(),(_vm.pageData.data.fields.read_time)?_c('span',{staticClass:"tag",class:{
                      'tag-ghost--black': _vm.pageData.data.fields.header_color === 'lychee',
                      'tag-ghost--white': _vm.pageData.data.fields.header_color === 'aubergine' || _vm.pageData.data.fields.header_color === 'rasberry',
                    }},[_c('ClockSvg',{staticClass:"icon-fix"}),_vm._v(" "+_vm._s(_vm.pageData.data.fields.read_time))],1):_vm._e()]),_c('h1',{staticClass:"article-detail__header--title"},[_vm._v(_vm._s(_vm.pageData.data.name))]),_c('div',{staticClass:"article-detail__header--author"},[(_vm.pageData.data.fields.author_avatar)?_c('img',{attrs:{"src":_vm.pageData.data.fields.author_avatar,"alt":_vm.pageData.data.fields.author}}):_vm._e(),_c('span',[(_vm.pageData.data.fields.author)?[_vm._v("Door: "+_vm._s(_vm.pageData.data.fields.author)+" | ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.pageData.data.fields.published_date))],2)])])])]),_c('div',{staticClass:"article-detail__header--content--half"},[(_vm.pageData.data.fields.image)?_c('figure',{staticClass:"article-detail__header--image"},[_c('img',{attrs:{"src":_vm.pageData.data.fields.image,"alt":_vm.pageData.data.fields.alt}})]):_vm._e()])])])]),_c('section',{staticClass:"article-detail__section"},[_c('div',{staticClass:"mt-l"},[_c('div',{staticClass:"text-block text-block__container--small"},[_c('p',{staticClass:"article-detail__intro"},[_vm._v(_vm._s(_vm.pageData.data.fields.intro))])])]),_vm._l((_vm.pageData.data.blocks),function(block,i){return [_c('div',{key:i,staticClass:"mt-l"},[_c(block.block,{tag:"component",attrs:{"data":block}})],1)]})],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }