
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { app } from '@config';
import { setTitle, handle429 } from '@util/util.ts';
import ClockSvg from '@svg/ClockMultiColor.vue';
import TextBlock from '@part/blocks/TextBlock.vue';
import QuoteBlock from '@part/blocks/QuoteBlock.vue';
import ImageBlock from '@part/blocks/ImageBlock.vue';
import LinkToContentBlock from '@part/blocks/LinkToContentBlock.vue';
import ImagesBlock from '@part/blocks/ImagesBlock.vue';
import AccordionBlock from '@part/blocks/AccordionBlock.vue';

import axios from 'axios';

/* -------------  ---------------------------
    Component
---------------------------------------- */

@Component({
  components: {
    ClockSvg,
    TextBlock,
    QuoteBlock,
    LinkToContentBlock,
    ImageBlock,
    ImagesBlock,
    AccordionBlock
  },
  data() {
    return {
      pageData: null,
      AccordionBlock: AccordionBlock,
      QuoteBlock: QuoteBlock,
      TextBlock: TextBlock,
      LinkToContentBlock: LinkToContentBlock,
      ImageBlock: ImageBlock,
      ImagesBlock: ImagesBlock
    };
  },
  mounted() {
    let $this = this;
    axios
      .get(`${app.api.api}/page/${$this.$route.meta.id}`)
      .then(response => {
        $this.pageData = response.data;
        setTitle(response);
      })
      .catch(error => {
        console.log(`🔥${error}🔥`);
        handle429(this, error);
      });
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class ArticlePage extends Vue {}
