

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';
import ClockSvg from '@svg/ClockMultiColor.vue';
import PinGhostSvg from '@svg/PinGhost.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    components: {
        ClockSvg,
        PinGhostSvg
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class LinkToContentBlock extends Vue {}

